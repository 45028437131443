import React, { CSSProperties, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,

} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";
import { fbAuth } from "./firebase/firebase";
import _environment from "./_environment/_environment";
import queryString from "query-string";
import { Spinner } from "react-bootstrap";
import Header from "./components/Header";
import { signOut } from "./services/authService";
import NotFoundView from "./views/NotFoundView";
import firebase from "firebase";
import { redirectWithAuthCode } from "./services/utils";
import { analytics } from './index';
import smartlookClient from 'smartlook-client';

//Lazy loading modules
const BrandLandingView = React.lazy(() => import('./views/landings/BrandLandingView'));
const SignUpView = React.lazy(() => import('./views/SignUpView'));
const BrandProfilingView = React.lazy(() => import('./views/profiling/brand/BrandProfilingView'));
const ProducerProfilingView = React.lazy(() => import('./views/profiling/producer/ProducerProfilingView'));
const ConfirmationView = React.lazy(() => import('./views/ConfirmationView'));
const ConfirmEmailView = React.lazy(() => import('./views/ConfirmEmailView'));
const ForgotPasswordView = React.lazy(() => import('./views/ForgotPasswordView'));
const ResetPasswordView = React.lazy(() => import('./views/ResetPasswordView'));
const SignInView = React.lazy(() => import('./views/SignInView'));
const InvitationView = React.lazy(() => import('./views/InvitationView'));


const Redirector = ({ ...props }) => {

  const history = useHistory();

  useEffect(() => {
    const query = queryString.parse(history.location.search);

    let backTo = query.back_to;

    if (!backTo) {
      backTo = `${_environment.webAppURL}login`;
    }

    if (backTo) {
      const url = new URL(backTo as string);
      let path = url.href.split("?")[0];
      redirectWithAuthCode(path, null, url.search);
    }

    return () => {};
  }, []);

  return <></>;
};

const object: CSSProperties = {
  fontWeight: 10
};

const Main = () => {
  const history = useHistory();

  useEffect(() => {
    analytics.page();
  }, [history.location.pathname]);

  useEffect(() => {
    smartlookClient.init('e30df27f8ee6749ec2a86d7bc4c2cce53de4791b');
  }, [])

  const mapUser = (user: firebase.User | null) => {
    if (!user) {
      return null;
    } else {
      return {
        displayName: user?.displayName,
        email: user?.email,
        photoURL: user?.photoURL,
        emailVerified: user?.emailVerified,
        // phoneNumber: user?.phoneNumber,
      };
    }
  };

  const [loading, setLoading] = useState<boolean>(true);

  const [authUser, setAuthUser] = useState<any>(null);

  // On init
  useEffect(() => {
    const query = queryString.parse(history.location.search);

    if (query.logout) {
      signOut();
    }

    // Enable listener
    fbAuth().onAuthStateChanged((user) => {
      console.log("onAuthStateChanged", user);
      if (!!user) {
        const query = queryString.parse(history.location.search);
        let backTo = query.back_to;

        if (!backTo) {
          backTo = `${_environment.webAppURL}login`;
        }

        if (backTo) {
          const url = new URL(backTo as string);

          let path = url.href.split("?")[0];

          redirectWithAuthCode(path, null, url.search);
        }

        setAuthUser(mapUser(user));
        setLoading(false);
      } else {
        setAuthUser(null);
        setLoading(false);
      }
    });

    return () => {};
  }, []);


  return (
    <>
      <div className="App">
        {!!loading && (
          <>
            <Header />
          </>
        )}
        {!loading && (
          <Switch>
            <Route
              path="/brand/join-us"
              exact
              render={(props) =>
                !authUser ? <BrandLandingView /> : <Redirector />
              }
            />

            <Route
              path="/sign-up"
              exact
              render={(props) => (!authUser ? <SignUpView /> : <Redirector />)}
            />

            <Route
              path="/sign-up/brand"
              exact={false}
              render={(props) =>
                !authUser ? <BrandProfilingView /> : <Redirector />
              }
            />

            <Route
              path="/sign-up/producer"
              exact={false}
              render={(props) =>
                !authUser ? <ProducerProfilingView /> : <Redirector />
              }
            />

            <Route
              path="/sign-up/confirmation"
              exact
              render={(props) =>
                !authUser ? <ConfirmationView /> : <Redirector />
              }
            />

            <Route
                path="/invite"
                exact
                render={(props) =>
                    !authUser ? <InvitationView /> : <Redirector />
                }
            />

            <Route
              path="/confirm-email"
              exact
              render={(props) =>
                !authUser ? <ConfirmEmailView /> : <Redirector />
              }
            />

            <Route
              path="/forgot-password"
              exact
              render={(props) =>
                !authUser ? <ForgotPasswordView /> : <Redirector />
              }
            />

            <Route
              path="/reset-password"
              exact
              render={(props) =>
                !authUser ? <ResetPasswordView /> : <Redirector />
              }
            />

            <Route
              path="/"
              exact
              render={(props) => (!authUser ? <SignInView /> : <Redirector />)}
            />

            <Route component={NotFoundView} />
          </Switch>
        )}
      </div>

      <Footer />
    </>
  );
};

const App = () => {

  return <>

    <React.Suspense fallback={
      <div className="text-center mt-5" style={{ minHeight: "500px" }}>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
    }>

      <Router>
        <Main></Main>
      </Router>
      <ToastContainer />

    </React.Suspense>
  </>

};

export default App;
