import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import "./assets/styles/styles.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { fbAuth, _initFirebase } from "./firebase/firebase";
import Analytics from 'analytics';
// @ts-ignore
import googleAnalytics from '@analytics/google-analytics';

// Sentry import
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Init firebase
_initFirebase();

export const analytics = Analytics({
  app: 'italian-artisan-account',
  plugins: [
    googleAnalytics({
      trackingId: process.env.REACT_APP_ENV === "production" ? 'UA-106163331-3' : ''
    })
  ],
  debug: true
});

Sentry.init({
  dsn: "https://78855a95bf1842aaa10e130c7d1246b1@o1230031.ingest.sentry.io/6415064",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
