import _environment from "../_environment/_environment";
import queryString from "query-string";

export enum Role {
  ADMIN = "admin",
  PRODUCER = "producer",
  BRAND = "brand",
}

export const getBriefRole = (role: Role): string => {
  switch (role) {
    case Role.ADMIN:
      return "A";
    case Role.PRODUCER:
      return "P";
    case Role.BRAND:
      return "B";
  }
}

/**
 * Slugify text
 *
 * @param text
 */
export const slugify = (text: string) => {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const storeTemporaryValues = (key: string, value: any) => {
  let _: any = {};
  try {
    _ = JSON.parse(localStorage.getItem("signUpValues") as string);

    if (!_) {
      _ = {};
    }
  } catch (err) {
    _ = {};
  }

  _[key] = value;

  localStorage.setItem("signUpValues", JSON.stringify(_));

  console.log("set", _);
};

export const getTemporaryValues = () => {
  let values: any = {};

  try {
    values = JSON.parse(localStorage.getItem("signUpValues") as string);
  } catch (err) {}

  return values;
};

export const getTemporaryValue = (
  key: string,
  fallBackValue: string | null = null
) => {
  let value: any = fallBackValue;
  try {
    value = JSON.parse(localStorage.getItem("signUpValues") as string)[key];
  } catch (err) {}

  return value;
};

export const redirectWithAuthCode = (
  url: string,
  authCode?: string | null,
  params?: string
) => {
  if (!authCode) {
    authCode = localStorage.getItem("authCode");
  }
  let queryParams;

  if (!!params) {
    queryParams = queryString.parse(params);
  }

  queryParams = { ...queryParams, authCode: authCode };

  let finalUrl = url;

  if (authCode && shouldOnboard(authCode)) {
    finalUrl = _environment.onboardingUrl;
  }

  window.location.href = `${finalUrl}?${queryString.stringify(queryParams)}`;
};

export const shouldOnboard = (authCode: string) => {
  const { role, is_fully_allowed, version } = JSON.parse(atob(authCode));
  return role === 'B' && !is_fully_allowed && version === 2;
}
