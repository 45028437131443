import * as development from "./development";
import * as staging from "./staging";
import * as production from "./production";

let environment: any = {
  development: development,
  staging: staging,
  production: production,
};

let index = !!process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : "development";

if (index === "development") {
  console.log(environment[index].default);
}

export default environment[index].default;
