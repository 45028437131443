import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { changeLanguage } from "../i18n";

export const LanguageMenu = () => {
  const { t } = useTranslation();

  return (
    <div className="menu">
      <p className="mb-3 font-bold">{t("footer.languages")}</p>

      <a
        className="mb-0"
        onClick={() => {
          changeLanguage("en-GB");
        }}
      >
        {t("footer.english")}
      </a>
      <a
        onClick={() => {
          changeLanguage("it-IT");
        }}
        className="mb-0"
      >
        {t("footer.italian")}
      </a>
    </div>
  );
};
