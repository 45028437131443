// -----------------------------------------------------------------
// D E V E L O P M E N T: env configuration
// -----------------------------------------------------------------

const environment = {
  firebase: {
    config: {
      apiKey: "AIzaSyCItzWhbPfswgUHBJLy7KcPxsACsJ4PNrk",
      authDomain: "italian-artisan-stage-898c0.firebaseapp.com",
      databaseURL: "https://italian-artisan-stage-898c0.firebaseio.com",
      projectId: "italian-artisan-stage-898c0",
      storageBucket: "italian-artisan-stage-898c0.appspot.com",
      messagingSenderId: "1002429327218",
      appId: "1:1002429327218:web:cbb8a47f7baefaf0d35feb",
    },
  },
  webAppURL: "http://localhost:4200/",
  marketplaceURL: "http://localhost:3001/",
  baseApiURL: "http://localhost:5000/v1/",
  baseApiURLV2: "http://localhost:5000/v2/",
  trackingApiURL: "https://ia-sauron-service-staging.herokuapp.com/",
  onboardingUrl: "http://localhost:3002/",
  endpoints: {
    login: "auth/login",
    hubSpot: "auth/hubspot",
    preRegisterBrand: "auth/brand/pre-register",
    createChatChannel: "auth/create-chat-channel",
    validateVAT: "users/validate-vat-number",
    registerProducer: "auth/producer/register",
    registerAdmin: "admin/register",
    track: "track"
  },
};

export default environment;
