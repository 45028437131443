// -----------------------------------------------------------------
// P R O D U C T I O N: env configuration
// -----------------------------------------------------------------
const environment = {
  firebase: {
    config: {
      apiKey: "AIzaSyA3oDvnoboPjyXtomo2eoI6JYJPJ0oGIY4",
      authDomain: "italian-artisan.firebaseapp.com",
      databaseURL: "https://italian-artisan.firebaseio.com",
      projectId: "italian-artisan",
      storageBucket: "italian-artisan.appspot.com",
      messagingSenderId: "740092147048",
      appId: "1:740092147048:web:70f8f2ba41d5bee1b312dd",
    },
  },
  webAppURL: "https://app.italian-artisan.com/",
  marketplaceURL: "https://marketplaces-italian-artisan.web.app/",
  baseApiURL: "https://api-italian-artisan.herokuapp.com/v1/",
  baseApiURLV2: "https://api-italian-artisan.herokuapp.com/v2/",
  trackingApiURL: "https://sauron-service.herokuapp.com/",
  onboardingUrl: "https://brand.italian-artisan.com/soft-quote",
  endpoints: {
    login: "auth/login",
    hubSpot: "auth/hubspot",
    preRegisterBrand: "auth/brand/pre-register",
    createChatChannel: "auth/create-chat-channel",
    validateVAT: "users/validate-vat-number",
    registerProducer: "auth/producer/register",
    registerAdmin: "admin/register",
    track: "track",
  },
};

export default environment;
