import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";

const NotFoundView = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <>
      <Header />
      <div className="container" style={{ minHeight: "100px" }}>
        <h3 className="text-center mt-5">Oops! Page not found</h3>
        <hr />
        <div className="text-center">
          <Link to={"/"}>Back to home</Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundView;
