import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fbAuth } from "../firebase/firebase";
import _environment from "../_environment/_environment";
import logo from "../assets/img/logos/black.png";

const Header = ({ hiddenMd, ...props }: any) => {
  return (
    <header className={`"app-header ${hiddenMd ? "hidden-md-up" : ""}`}>
      <Container>
        <a className="d-block logo">
          <img src={logo} />
        </a>
      </Container>
    </header>
  );
};

export default Header;
