import Axios from "axios";
import firebase from "firebase";
import { fbAuth } from "../firebase/firebase";
import _environment from "../_environment/_environment";

export const loginWithCredentials = async (
  email: string,
  password: string
): Promise<any> => {
  try {
    console.log("auth", "Sign in with credentials...");
    const res = await Axios.post(
      `${_environment.baseApiURL}${_environment.endpoints.login}`,
      { email, password }
    );

    const code = Buffer.from(JSON.stringify(res.data.data)).toString("base64");

    console.log("auth", "setting authCode in storage");
    localStorage.setItem("authCode", code);

    return {
      code,
      token: res.data.data.token
    };
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const loginWithAuthCode = async (): Promise<any> => {
  try {
    console.log("Sign in with auth code...");
    const authCode = localStorage.getItem("authCode");

    if (!!authCode) {
      const data = JSON.parse(atob(authCode as string));
      await fbAuth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await fbAuth().signInWithCustomToken(data.token);
    }
  } catch (err) {
    throw err;
  }
};

export const forgotPassword = async (email: string): Promise<any> => {
  try {
    await Axios.post(`${_environment.baseApiURL}auth/forgot-password`, {
      email,
    });

    return true;
  } catch (err) {
    throw err;
  }
};

export const resetPassword = async (
  token: string,
  password: string
): Promise<any> => {
  try {
    await Axios.post(`${_environment.baseApiURL}auth/reset-password`, {
      token,
      password,
    });

    return true;
  } catch (err) {
    throw err;
  }
};

export const signOut = () => {
  console.log("auth", "logout");
  localStorage.removeItem("authCode");
  fbAuth().signOut();
};

export const getProfile = async (): Promise<any> => {
  try {
    const res = await Axios.get(`${_environment.baseApiURL}users/me/profile`, {
      headers: {
        ia_auth_token: await fbAuth().currentUser?.getIdToken(),
      },
    });

    return res.data.data;
  } catch (err) {
    throw err;
  }
};
