import firebase from "firebase";
import _environment from "../_environment/_environment";

export const _initFirebase = () => {
  console.log("Firebase, initializing app...");

  firebase.initializeApp(_environment.firebase.config);

  console.log(`[Firebase] App started in ${process.env.REACT_APP_ENV} mode`);
};

let firestoreRef: firebase.firestore.Firestore;
let authRef: firebase.auth.Auth;
let storageRef: firebase.storage.Storage;
let analyticsRef: firebase.analytics.Analytics;

export const fbFirestore = (): firebase.firestore.Firestore => {
  console.log("Firebase, retrieving firestore");
  if (!firestoreRef) {
    console.log("Firebase, init firestore");

    firestoreRef = firebase.firestore();
  }

  return firestoreRef;
};

export const fbAuth = (): firebase.auth.Auth => {
  console.log("Firebase, retrieving auth");

  if (!authRef) {
    console.log("Firebase, init auth");

    authRef = firebase.auth();
  }

  return authRef;
};

export const fbAnalytics = (): firebase.analytics.Analytics => {
  console.log("Firebase, retrieving analytics");

  if (!analyticsRef) {
    console.log("Firebase, init analytics");

    analyticsRef = firebase.analytics();
  }

  return analyticsRef;
};

export const fbStorage = (): firebase.storage.Storage => {
  console.log("Firebase, retrieving storage");

  if (!storageRef) {
    console.log("Firebase, init storage");

    storageRef = firebase.storage();
  }

  return storageRef;
};
