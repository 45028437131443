import React, { useEffect, useState } from "react";
import footerImg from "../assets/img/backgrounds/5.jpg";
import logoWhite from "../assets/img/logos/white.png";
import _environment from "../_environment/_environment";
import moment from "moment";
import { changeLanguage } from "../i18n";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageMenu } from "./LanguageMenu";

const Footer = ({ user, ...props }: any) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [showMenu, setShowMenu] = useState<boolean>(true);
  const [hideLanguages, setHideLanguages] = useState(false);

  useEffect(() => {
    const pagesWithoutFooter = [
      "/brand/join-us",
      "/sign-up",
      "/sign-up/brand/step-1",
      "/sign-up/brand/step-2",
      "/sign-up/brand/step-3",
      "/sign-up/brand/step-4",
      "/sign-up/brand/step-5",
      "/sign-up/brand/step-6",
      "/sign-up/producer/step-1",
      "/sign-up/producer/step-2",
      "/sign-up/producer/step-3",
      "/sign-up/producer/step-4",
      "/sign-up/producer/step-5",
    ];

    if (pagesWithoutFooter.includes(history.location.pathname)) {
      setShowMenu(false);
    }

    setHideLanguages(history.location.pathname === '/brand/join-us');

    history.listen((location: any) => {
      //Do your stuff here
      if (pagesWithoutFooter.includes(history.location.pathname)) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }

      setHideLanguages(history.location.pathname === '/brand/join-us');
    });
  }, []);

  return (
    <>
      <footer id="footer">
        <div className="main">
          <div className="container">
            <div className="logo">
              <a href="https://italian-artisan.com" target="_blank">
                <img src={logoWhite} />
              </a>
            </div>

            {!showMenu && !hideLanguages && <LanguageMenu />}
            {!!showMenu && (
              <>
                <div className="menu">
                  <p className="mb-3 font-bold">{t("footer.services" as any)}</p>
                  <a href={_environment.webAppURL} className="mb-0">
                    {t("footer.b2b")}
                  </a>
                  <a href={_environment.marketplaceURL} className="mb-0">
                    {t("footer.catalogue")}
                  </a>
                </div>
                <div className="menu">
                  <p className="mb-3 font-bold">{t("footer.support")}</p>
                  <a
                    href="https://italian-artisan.com/contact/"
                    className="mb-0"
                  >
                    {t("footer.contactUs")}
                  </a>
                  <a
                    href="https://italian-artisan.com/frequently-asked-questions/"
                    className="mb-0"
                  >
                    {t("footer.faq")}
                  </a>
                  <a
                    href="https://italian-artisan.com/#HowItWorks"
                    className="mb-0"
                  >
                    {t("footer.howItWorks")}
                  </a>
                  <a
                    href="https://italian-artisan.com/#Pricing"
                    className="mb-0"
                  >
                    {t("footer.pricing")}
                  </a>
                </div>
                <LanguageMenu />
                <div className="menu">
                  <p className="mb-3 font-bold">{t("footer.social")}</p>
                  <a
                    href="https://www.facebook.com/italianartisan/"
                    className="mb-0"
                    target="_blank"
                  >
                    {t("footer.facebook")}
                  </a>
                  <a
                    href="https://www.instagram.com/italian_artisan/"
                    className="mb-0"
                    target="_blank"
                  >
                    {t("footer.instagram")}
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCXbBexKdM41bDX8Vjtqu-Lg"
                    className="mb-0"
                    target="_blank"
                  >
                    {t("footer.youTube")}
                  </a>
                  <a
                    href="https://it.linkedin.com/company/italian-artisan"
                    className="mb-0"
                    target="_blank"
                  >
                    {t("footer.linkedIn")}
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="copy">
          <div className="container">
            <a>Copyright © {moment().format("YYYY")} Italian Artisan</a>
            <a>Artisan DNA Srl | P.IVA: 01866390436</a>
            <a
              href="https://italian-artisan.com/privacy-policy/"
              target="_blank"
            >
              {t("footer.privacyPolicy")}
            </a>
            <a
              href="https://italianartisan.com/termini-e-condizioni/"
              target="_blank"
            >
              {t("footer.termsConditions")}
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
