// -----------------------------------------------------------------
// S T A G I N G: env configuration
// -----------------------------------------------------------------
const environment = {
  firebase: {
    config: {
      apiKey: "AIzaSyCItzWhbPfswgUHBJLy7KcPxsACsJ4PNrk",
      authDomain: "italian-artisan-stage-898c0.firebaseapp.com",
      databaseURL: "https://italian-artisan-stage-898c0.firebaseio.com",
      projectId: "italian-artisan-stage-898c0",
      storageBucket: "italian-artisan-stage-898c0.appspot.com",
      messagingSenderId: "1002429327218",
      appId: "1:1002429327218:web:cbb8a47f7baefaf0d35feb",
    },
  },
  webAppURL: "https://artisan-web-dev.herokuapp.com/",
  marketplaceURL: "https://market-italian-artisan-stage.web.app/",
  baseApiURL: 'https://api-italian-artisan.onrender.com/v1/',
  baseApiURLV2: "https://api-italian-artisan.onrender.com/v2/",
  trackingApiURL: "https://ia-sauron-service-staging.herokuapp.com/",
  onboardingUrl: "https://onboarding-frontend-bb3b.onrender.com/soft-quote",
  endpoints: {
    login: "auth/login",
    hubSpot: "auth/hubspot",
    preRegisterBrand: "auth/brand/pre-register",
    createChatChannel: "auth/create-chat-channel",
    validateVAT: "users/validate-vat-number",
    registerProducer: "auth/producer/register",
    registerAdmin: "admin/register",
    track: "track",
  },
};

export default environment;
